import React from "react";
import { Helmet } from "react-helmet";
import ContactFormV2 from "../../components/ContactFormV2";
import Layout from "../../components/Layout";
import LocationsGrid from "../../components/LocationsGrid";
import Seo from "../../components/seo";
import ogIMage from "../../images/og.png";

const ContactPage = ({ location }) => {
    return (
        <Layout>
            {/* <Helmet>
                <script
                    async
                    // src="https://www.googletagmanager.com/gtag/js?id=UA-131005556-2"
                ></script>
                <script
                    key={"tacking-code"}
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-131005556-2');`,
                    }}
                />
            </Helmet> */}
            <Seo
                title="Contact Sedin for all your Technology Needs"
                description={
                    "With our global positioned offices, we make sure you never miss us. If you are as passionate as we are about future possibilities, please feel free to contact us. "
                }
                url={location.href}
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section>
                <div className="container container--py">
                    <ContactFormV2 />
                </div>
            </section>
            <section className="contact-locations">
                <div className="container container--last">
                    <LocationsGrid />
                </div>
            </section>
        </Layout>
    );
};

export default ContactPage;
